import Entity from "../entity";
import ItemTiket from "./itemTiket";

export default class Ticket extends Entity<number>{
    participacionId: number;
    imagenValidacion: string;
    retailer: string;
    file_name: string;
    date: string;
    date_score: number;
    store: string;
    store_score: number;
    address: string;
    address_score: number;
    zipcode: string;
    zipcode_score: number;
    scitytore: string;
    city_score: number;
    amount: string;
    amount_score: number;
    taxes: string;
    taxes_score: number;
    items: Array<ItemTiket> = [];
    itemsCampana: Array<ItemTiket> = [];
    eans:Array<ItemTiket>= [];
    recortado:string;
}