

import EstadoEnvio from "../../../store/entities/OZONE/estadoEnvio";
import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";
import AbpBase from "../../../lib/abpbase";
import moment from 'moment';

@Component
export default class HistorialEnvios extends AbpBase {
  name = "historial-envios";

  @Prop({ type: Boolean, default: false }) value: boolean;
  @Prop({ type: Number, default: null }) id: number;

  estadosEnvio: Array<EstadoEnvio> = [];
  estadosEnvialia: Array<EstadoEnvio> = [];
  error: boolean = false;

  @Watch("value")
  async onOpenClose() {
    if (this.value && this.id) {
      await this.$store
        .dispatch({
          type: "envio/getHistorial",
          data: { envioId: this.id },
        })
        .then((data) => {
          this.estadosEnvio = data;
        })
        .catch((e) => {
          this.error = true;
        });
    }
    else{
        this.estadosEnvio = [];
    }
  }

  get loading() {
    return this.$store.state.envio.loading;
  }

  parseDate(date){
      return moment(date).format('DD/MM/YYYY') 
  }

  handleOk() {
    this.$emit("input", false);
  }
}
