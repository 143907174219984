
import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";
import Util from "../../../lib/util";
import AbpBase from "../../../lib/abpbase";
import Modal from "../../../components/Modal.vue";
import BaseButton from "../../../components/BaseButton.vue";
import BaseCheckbox from "../../../components/Inputs/BaseCheckbox.vue";
import Cropper from "cropperjs";
import Ticket from "../../../store/entities/OZONE/ticket";
import ProcesaImagenParticipacion from "./procesa-Imagen-participacion.vue";
import ProcesaImagenEanParticipacion from "./procesa-ImagenEan-participacion.vue";
import PageRequest, { maxResults } from "../../../store/entities/page-request";
import moment from "moment";
import EditableCell from "./editableCell.vue";
import ItemTiket from "../../../store/entities/OZONE/itemTiket";

import { extend } from "vee-validate";
import EanTicket from "../../../store/entities/OZONE/eanTicket";

class PageEansRequest extends PageRequest {
  campanaId: number;
}

@Component({
  components: {
    Modal,
    BaseButton,
    BaseCheckbox,
    ProcesaImagenParticipacion,
    ProcesaImagenEanParticipacion,
    EditableCell,
  },
})
export default class EditImagenParticipacion extends AbpBase {
  name: "edit-estados-participacion";
  @Prop({ type: Boolean, default: false }) value: boolean;
  @Prop({ type: Boolean, default: false }) editing: boolean;
  @Prop({ type: String }) id: string;
  @Prop({ default: "" }) editFile: any;
  @Prop({ type: Boolean, default: false }) ticketData: boolean;
  @Prop({ type: Boolean, default: false }) ticketean: boolean;
  @Prop({ type: Boolean, default: false }) disabled: boolean;
  @Prop({ type: Number, default: 0 }) campanaId: number;

  title = "";
  inputFile: any = { blob: "" };
  ticket: Ticket = new Ticket();
  ean: Ticket = new Ticket();
  eanTicket: EanTicket = new EanTicket();
  editModalShowProcesaTiket: boolean = false;
  editModalShowProcesaEan: boolean = false;
  fileName = "";
  fileNameEan = "";
  fileContent: any = null;
  fileContentEan: any = null;
  date: string = "";
  eansRequest: PageEansRequest = new PageEansRequest();
  eansByCampana: Array<any> = [];
  thereAreEans: boolean = false;
  listEansTicket: Array<any> = [];
  listEansTicket2: Array<any> = [];
  rules: Array<any> = [];
  visible = false;
  itemsEans : ItemTiket
  isEan: boolean = false;

  showErrorMessage: boolean = false
  errorMessage: string = ''

  get isDisabled() {
    return this.disabled;
  }

  async created() {}
  async save() {
    var isValid = this.validateTicketRules();
    if(isValid){
      if(this.isEan==false){
        if (this.inputFile && this.inputFile.cropper) {
          if(this.ticket.date){
            this.ticket.date = moment(this.ticket.date).utcOffset(0).set({second: 0, millisecond: 0}).toISOString();
          }
          
          this.$emit(
            "save-success",
            this.inputFile.cropper
              .getCroppedCanvas({ imageSmoothingEnabled: false })
              .toDataURL(),
            (this.ticket)
          );
        }
      }
      else{
        if (this.inputFile && this.inputFile.cropper) {
          this.$emit(
            "save-success",
            this.inputFile.cropper
              .getCroppedCanvas({ imageSmoothingEnabled: false })
              .toDataURL(),
            (this.eanTicket)
          );
        }
      }
          
      this.$emit("input", false);
      this.resetFields();
    }
  }
  close() {
    this.$emit("input", false);
    this.resetFields();
  }

  get reglasTicketDato() {
    return this.$store.state.campana.reglasTicketDato.filter(
      (x) => x.reglasTicketId != null
    );
  }

  async shown() {
    this.title = "Edita Imagen";
    this.inputFile = { blob: this.editFile };
    let tickets = [];
    this.eanTicket = this.$store.state.participacion.editParticipacion.eanTicket;
    if (
      this.$store.state.participacion.editParticipacion.ticket &&
      this.$store.state.participacion.editParticipacion.ticket.length > 0
    ) {
      tickets = Util.extend(
        [{}],
        [{}],
        this.$store.state.participacion.editParticipacion.ticket
      );
    }

    if (tickets.length > 0) {
      
      let index = tickets.findIndex(
        (x) => x.imagenValidacion == this.editFile.file.name
      );
      if (index === -1) {
        this.ticket = new Ticket();
        this.getItemsEansdelEan();
        this.ticket.imagenValidacion = this.editFile.file.name;
      } else {
              
        this.ticket = tickets[index];              
            
      }
      //this.ean.eans = this.$store.state.participacion.editParticipacion.ticket[0].items
    }
    
    await this.getEansByCampana();
    this.getItemsTicket();
  
    await this.getReglasTicket();

    this.editModalShowProcesaTiket = false;
    this.editModalShowProcesaEan = false;
    this.makeRules("ARTICULO", 6);
    this.makeRules("OTROS", 7);

    this.$nextTick(() => {
      const valid = (this.$refs.observerImagen as any).validate();
    });
  }

  async getReglasTicket() {
    await this.$store.dispatch({
      type: "campana/GetAllReglasTicket",
      data: {
        //campanaId: this.$store.state.participacion.editParticipacion.campanaId,
        campanaId: this.campanaId
      },
    });
  }

  validateTicketRules(){
    let rulesValidated = true;
    let articuloRule = Object.getOwnPropertyNames(this.rules[6])[0]
    let articuloRuleValue = this.rules[6][articuloRule]

    switch(articuloRule){
      case "is":
        rulesValidated = this.checkArticuloIsRule(articuloRuleValue)
        break;
      case "oneOf":
        rulesValidated = this.checkArticuloOneOfRule(articuloRuleValue)
        break;
      case "min_value":
        rulesValidated = this.checkArticuloSmallerEqualRule(articuloRuleValue)
        break;
      case "max_value":
        rulesValidated = this.checkArticuloGreaterEqualRule(articuloRuleValue)
        break;
      case "between":
        rulesValidated = this.checkArticuloBetweenRule(articuloRuleValue)
        break;
      case "excluded":
        rulesValidated = this.checkArticuloExcludedRule(articuloRuleValue)
        break;
      default:
        if(this.ticket.items.length <= 0){
          rulesValidated = false;
          this.showErrorMessage = true;
          this.errorMessage = 'El artículo es obligatorio.'
        }
        break;
    }

    return rulesValidated;
  }

  // #region checks for articulo rules
  checkArticuloIsRule(ruleValue){
    let item = this.ticket.items.find((x) => x.name == ruleValue)

    if(item == null){
      this.showErrorMessage = true;
      this.errorMessage = 'El artículo ' + ruleValue + ' no se ha encontrado.'
    }

    return item != null
  }

  checkArticuloOneOfRule(values: Array<any>){
    let valuesTrimmed = values.map(value => value.trim())
    let isOneOf = this.ticket.items.some(item => {
      return valuesTrimmed.includes(item.name)
    });
    
    if(!isOneOf){
      this.showErrorMessage = true;
      this.errorMessage = 'El artículo debe ser uno de los siguientes: ' + JSON.stringify(values)
    }

    return isOneOf;
  }

  checkArticuloExcludedRule(values: Array<any>){
    let isExcluded = !this.checkArticuloOneOfRule(values);

    if(!isExcluded){
      this.showErrorMessage = true;
      this.errorMessage = 'El artículo no puede ser uno de los siguientes: ' + JSON.stringify(values)
    }

    return isExcluded;
  }

  checkArticuloBetweenRule(values: Array<any>){
    let isInBetween = this.ticket.items.some((item) => item.name < values[0] && item.name > values[1])
    if(!isInBetween){
      this.showErrorMessage = true;
      this.errorMessage = 'El artículo debe estar entre los valores ' + values[0] + ' y ' + values[1]
    }
    return isInBetween;
  }

  checkArticuloGreaterEqualRule(value){
    let isGreaterOrEqual = this.ticket.items.some((item) => item.name >= value)

    if(!isGreaterOrEqual){
      this.showErrorMessage = true;
      this.errorMessage = 'El arículo debe sear mayor o igual que ' + value
    }

    return isGreaterOrEqual  
  }

  checkArticuloSmallerEqualRule(value){
    let isSmallerOrEqual = this.ticket.items.some((item) => item.name <= value)

    if(!isSmallerOrEqual){
      this.showErrorMessage = true;
      this.errorMessage = 'El arículo debe sear menor o igual que ' + value
    }

    return isSmallerOrEqual  
  }
  // #endregion

  checkFormValidity() {
    const valid = (this.$refs.estadoParticipacionForm as any).checkValidity();
    return valid;
  }

  resetFields() {
    this.inputFile = { ...this.inputFile, blob: "" };
    this.ticket = new Ticket();
    this.showErrorMessage = false;
    this.errorMessage = '';
  }

  @Watch("inputFile.blob")
  inputFileChange(newValue, oldValue) {
    if (newValue && newValue !== oldValue) {
      this.$nextTick(() => {
        let cropper = new Cropper(this.$refs.editImage as any, {
          autoCrop: false,
        });
        this.inputFile = { ...this.inputFile, cropper: cropper };
      });
    }
  }

  async getEansByCampana() {
    this.eansRequest.campanaId = this.campanaId
    this.eansRequest.maxResultCount = maxResults.maxResultCount;
    this.eansRequest.skipCount = maxResults.skipCount;

    await this.$store.dispatch({
      type: "campana/getAllEans",
      data: this.eansRequest,
    });

    this.eansByCampana = this.$store.state.campana.eansList;
    this.thereAreEans = this.eansByCampana.length > 0;
  }

  async savesuccessTiket(ticketInfo: Ticket) {
    const imagen = this.ticket.imagenValidacion;
    if (ticketInfo === null || !ticketInfo) {
      this.$bvModal.msgBoxOk("Error en procesado", {
        title: "Procesado de Imagen",
        size: "sm",
        buttonSize: "sm",
        okVariant: "danger",
        headerClass: "p-2 border-bottom-0",
        footerClass: "p-2 border-top-0",
        centered: true,
      });
    }
    if (ticketInfo) {
      if (this.ticket.id) {
        await this.$store.dispatch({
          type: "participacion/deleteTicket",
          data: { id: this.ticket.id },
        });
      }
      this.ticket = ticketInfo;
      this.ticket.imagenValidacion = imagen;
      this.ticket.date = this.getDate(ticketInfo.date);

      this.getItemsTicket();
      this.$nextTick(() => {
        const valid = (this.$refs.observerImagen as any).validate();
      });
    }
  }

  async savesuccessEan(ticketEan: EanTicket) {
    const imagen = this.editFile.file.name;
    if (ticketEan === null || !ticketEan) {
      this.$bvModal.msgBoxOk("Error en procesado", {
        title: "Procesado de Imagen",
        size: "sm",
        buttonSize: "sm",
        okVariant: "danger",
        headerClass: "p-2 border-bottom-0",
        footerClass: "p-2 border-top-0",
        centered: true,
      });
    }
    if (ticketEan) {
      if (this.ticket.id) {
        await this.$store.dispatch({
          type: "participacion/deleteTicket",
          data: { id: this.ticket.id },
        });
      }

      
      this.eanTicket = ticketEan;
      this.eanTicket.imagenValidacion = imagen;
      
      //this.ticket.date = this.getDate(ticketInfo.date);

      this.getItemsEansdelEan();
      
      this.$nextTick(() => {
        const valid = (this.$refs.observerImagen as any).validate();
      });
    }
  }

  getDate(date: string) {
    if (date.length <= 8) {
      return moment(date, "DD/MM/YY").format("YYYY-MM-DDTHH:MM:SS").toString();
    } else {
      return moment(date, "DD/MM/YYYY")
        .format("YYYY-MM-DDTHH:MM:SS")
        .toString();
    }
  }

  getItemsTicket() {
    if(this.thereAreEans){
      this.ticket.items = this.ticket.items.filter(
        (item) =>
          item.barcode != null &&
          this.eansByCampana.some((ean) =>
            item.barcode.split(",").some((bc) => ean.cod_Ean === bc)
          )
      );
      this.ticket.items = this.ticket.items.map((item) => {
        if (
          this.eansByCampana.some((ean) =>
            item.barcode.split(",").some((bc) => ean.cod_Ean === bc))
        ) {
          return (item = {
            ...item,
            name: this.eansByCampana.find((ean) =>
              item.barcode.split(",").some((bc) => ean.cod_Ean === bc)
            ).titulo,
            barcode: this.eansByCampana.find((ean) =>
              item.barcode.split(",").some((bc) => ean.cod_Ean === bc)
            ).cod_Ean,
          });
        }
      });
    }
  }

  getItemsEansdelEan() {
    var listEansTicket= this.$store.state.participacion.editParticipacion.ticket  
    
    if(this.eanTicket !=null){
      listEansTicket[0].items.forEach(element => {
          
        if(element.barcode == this.eanTicket.barcode){
          var ticketItems = new Array<ItemTiket>();
          var ticketItem = new ItemTiket();
          ticketItem.barcode = this.eanTicket.barcode
          ticketItems.push(ticketItem);
          this.ean.items=ticketItems;
          this.isEan = true;
        }
      });
    }
  }

  async postfile() {
    this.fileName = this.generateUUID() + ".JPEG";

    this.fileContent = this.dataURLtoFile(
      this.inputFile.cropper
        .getCroppedCanvas({ imageSmoothingEnabled: false })
        .toDataURL(),
      this.fileName
    );
  }

  async postfileEan() {
    
    this.fileNameEan = this.generateUUID() + ".JPEG";
    this.fileContentEan = this.editFile.file;
    return;

    this.fileContentEan = this.editFile.file;
    return;
    //ME CAGO EN SU PUTISIMA MADRE
    this.fileContentEan = this.dataURLtoFileEan(
      this.inputFile.cropper
        .getCroppedCanvas({ imageSmoothingEnabled: false })
        .toDataURL(),
      this.fileNameEan
    );
  }

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
  dataURLtoFileEan(dataurl, filenameEan) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filenameEan, { type: mime });
  }

  generateUUID() {
    // Public Domain/MIT
    var d = new Date().getTime(); //Timestamp
    var d2 =
      (typeof performance !== "undefined" &&
        performance.now &&
        performance.now() * 1000) ||
      0; //Time in microseconds since page-load or 0 if unsupported
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = Math.random() * 16; //random number between 0 and 16
        if (d > 0) {
          //Use timestamp until depleted
          r = (d + r) % 16 | 0;
          d = Math.floor(d / 16);
        } else {
          //Use microseconds since page-load if supported
          r = (d2 + r) % 16 | 0;
          d2 = Math.floor(d2 / 16);
        }
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
  }

  onCellChange(key, dataIndex, value) {
    const dataSource = [...this.ticket.items]
    const target = dataSource.find((item) => (item.id && item.id === key) || (item.uuid && item.uuid === key))
    if (target) {
      target[dataIndex] = value.trim();
      this.ticket.items = dataSource;
    }
    const dataSourceItems = [...this.ticket.items];
    const targetItems = dataSourceItems.find((item) => (item.id && item.id === key) || (item.uuid && item.uuid === key));
    if (targetItems) {
      targetItems[dataIndex] = value.trim();
      this.ticket.items = dataSourceItems;
    }
  }

  onCellChangeEan(key, dataIndex, value) {
    const dataSource = [...this.ean.items]
    const target = dataSource.find((item) => item.barcode === key)
    if (target) {
      target[dataIndex] = value.trim();
      this.ean.items = dataSource;
    }
    const dataSourceItems = [...this.ean.items];
    const targetItems = dataSourceItems.find((item) => item.barcode === key);
    if (targetItems) {
      targetItems[dataIndex] = value.trim();
      this.ean.items = dataSourceItems;
    }
  }

  async onDelete(key) {
    
    this.visible = false;
    const dataSource = [...this.ticket.items];
    const dataSourceItems = [...this.ticket.items];
    if (key.id) {
      this.ticket.items = dataSource.filter(
        (item) => item.id !== key.id
      );
      this.ticket.items = dataSourceItems.filter((item) => item.id !== key.id);
    } else {
      this.ticket.items = dataSource.filter(
        (item) => item.name !== key.name && item.barcode != key.barcode
      );
      this.ticket.items = dataSourceItems.filter(
        (item) => item.name !== key.name && item.barcode != key.barcode
      );
    }
    if (key.id) {
      await this.$store.dispatch({
        type: "participacion/deleteItemTicket",
        data: { id: key.id },
      });
    }
    this.$nextTick(() => {
      const valid = (this.$refs.observerImagen as any).validate();
    });
  }
  async onDeleteEan(key) {
    
    this.visible = false;
    const dataSource = [...this.ean.items];
    const dataSourceItems = [...this.ean.items];
    if (key.id) {
      this.ean.items = dataSource.filter(
        (item) => item.id !== key.id
      );
      this.ean.items = dataSourceItems.filter((item) => item.id !== key.id);
    } else {
      this.ean.items = dataSource.filter(
        (item) => item.barcode != key.barcode
      );
      this.ean.items = dataSourceItems.filter(
        (item) => item.barcode != key.barcode
      );
    }
    if (key.id) {
      await this.$store.dispatch({
        type: "participacion/deleteItemTicket",
        data: { id: key.id },
      });
    }
    this.$nextTick(() => {
      const valid = (this.$refs.observerImagen as any).validate();
    });
  }

  handleAdd() {
    let item = this.thereAreEans ? { uuid: this.generateUUID(), name: "Artículo", barcode: "EAN"} : { uuid: this.generateUUID(), name: "Artículo"}
    this.ticket.items.push(
      Object.assign(new ItemTiket(), item)
    );
   
    this.$nextTick(() => {
      const valid = (this.$refs.observerImagen as any).validate();
    });
  }

  handleAddEan() {
    this.ean.items.push(
      Object.assign(new ItemTiket(), {barcode: "EAN" })
    );
   
    this.$nextTick(() => {
      const valid = (this.$refs.observerImagen as any).validate();
    });
  }

  rowKey() {
    return this.generateUUID();
  }

  makeRules(item, index) {
    this.rules[index] = "";
    if (this.reglasTicketDato !== null) {
      const rule = this.reglasTicketDato.find(
        (x) => x.reglasTicketNombre === item
      );
      if (rule) {
        try {
          let ruleCampo = JSON.parse(
            this.reglasTicketDato.find((x) => x.reglasTicketNombre === item)
              .regla
          );
          if (ruleCampo !== null) {
            ruleCampo = { ...ruleCampo, required: true };
          }
          this.rules[index] = ruleCampo;
        } catch {}
      }
    }
  }

  columns = [
    {
      title: "Artículo",
      dataIndex: "name",
      width: "60%",
      scopedSlots: { customRender: "name" },
    },
    {
      title: "EAN",
      dataIndex: "barcode",
      width: "40%",
      scopedSlots: { customRender: "barcode" },
    },
    {
      title: "",
      dataIndex: "operation",
      scopedSlots: { customRender: "operation" },
    },
  ];

  columnsArticulos = [
    {
      title: this.L("Artículo"),
      dataIndex: "name",
      with: "100%",
      scopedSlots: { customRender: "name"}
    },
    {
      title: "",
      dataIndex: "operation",
      scopedSlots: { customRender: "operation" },
    }
  ];

  columnsEan=[
    {
      title: "EAN",
      dataIndex: "barcode",
      width: "40%",
      scopedSlots: { customRender: "barcode" },
    },
    {
      title: "",
      dataIndex: "operation",
      scopedSlots: { customRender: "operation" },
    },
  ]
}
