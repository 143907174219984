
import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator'
import Util from '@/lib/util'
import AbpBase from '@/lib/abpbase'
import PageRequest from '@/store/entities/page-request'
import Modal from '../../../components/Modal.vue'
import DetailHistory from './detail-history.vue'

class PageHistoryRequest extends PageRequest {
  entityTypeFullName: string = ''
  entityId: string = ''
}

@Component({
  components: { Modal, DetailHistory }
})
export default class History extends AbpBase {
  @Prop({ type: Boolean, default: false }) value: boolean
  @Prop({ type: String }) id: string
  @Prop({ type: String }) entityTypeFullName: string
  @Prop({ type: Number }) entityId: number
  @Prop({ type: String }) title: string
  //filters
  pagerequest: PageHistoryRequest = new PageHistoryRequest()
  pagination: any = {}
  viewModalShow = false
  entityChangeSetId: number = 0

  get list() {
    
    return this.$store.state.audit.list
  }
  get loading() {
    return this.$store.state.audit.loading
  }
  async create() {}

  pageChange(page: number) {
    this.$store.commit('audit/setCurrentPage', page)
    this.getpage()
  }
  pagesizeChange(pagesize: number) {
    this.$store.commit('audir/setPageSize', pagesize)
    this.getpage()
  }
  async getpage() {
    
    this.pagerequest.maxResultCount = this.pageSize
    this.pagerequest.skipCount = (this.currentPage - 1) * this.pageSize
    this.pagerequest.entityTypeFullName = this.entityTypeFullName
    this.pagerequest.entityId = this.entityId.toString()
    await this.$store.dispatch({
      type: 'audit/getAll',
      data: this.pagerequest
    })

    const pagination = { ...this.pagination }
    pagination.total = this.$store.state.audit.totalCount
    pagination.pageSize = this.$store.state.audit.pageSize
    pagination.current = this.$store.state.audit.currentPage
    this.pagination = pagination
  }
  get pageSize() {
    return this.$store.state.audit.pageSize
  }
  get totalCount() {
    return this.$store.state.audit.totalCount
  }
  get currentPage() {
    return this.$store.state.audit.currentPage
  }
  set currentPage(page) {
    this.$store.commit('audit/setCurrentPage', page)
    this.getpage()
  }

  handleTableChange = async (pagination, filters, sorter) => {
    this.$store.commit('audit/setCurrentPage', pagination.current)
    this.getpage()
  }
  shown() {
    this.getpage()
  }

  close() {
    this.$emit('input', false)
  }

  async created() {}

  viewRow(item, index, button) {
      
    this.entityChangeSetId = item.entityChangeSetId
    this.viewModalShow = true
  }

  columns = [
    {
      title: this.L('Action'),
      dataIndex: 'changeTypeName'
    },
    {
      title: this.L('User'),
      dataIndex: 'userName'
    },
    {
      title: this.L('Fecha'),
      dataIndex: 'changeTime',
      scopedSlots: { customRender: 'date' }
    },
    {
      scopedSlots: { customRender: 'detailBtn' },
      width: 10,
      title: ''
    }
  ]
}
