
import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";
import Util from "../../../lib/util";
import AbpBase from "../../../lib/abpbase";
import Modal from "../../../components/Modal.vue";
import BaseButton from "../../../components/BaseButton.vue";
import BaseCheckbox from "../../../components/Inputs/BaseCheckbox.vue";
import Participacion from "../../../store/entities/OZONE/participacion";
import BaseDatePicker from "../../../components/Inputs/BaseDatePicker.vue";
import PageRequest, { maxResults } from "../../../store/entities/page-request";
import ParticipacionDato from "../../../store/entities/OZONE/participacionDato";
import ParticipacionComentario from "../../../store/entities/OZONE/participacionComentario";
import SelectCampana from "../campana/selectCampana.vue";
import { EstadoParticipacion } from "../../../store/entities/OZONE/estadoParticipacion";
import SubEstadoParticipacion from "../../../store/entities/OZONE/subEstadoParticipacion";
import RuleCheckbox from "../campo/ruleCheckbox.vue";
import FileAgent from "../../../components/fileAgent.vue";
import EditImagenParticipacion from "./edit-Imagen-participacion.vue";
import { Alert, Result } from "ant-design-vue";
import Ticket from "../../../store/entities/OZONE/ticket";
import Campana from "../../../store/entities/OZONE/campana";
import PeopleHeader from "../../../components/People/people-header.vue";
import Transferencia from "../../../store/entities/OZONE/transferencia";
import TransferenciaHistory from "../transferencia/transferencia-history.vue";
import ValidateImagenCampana from "../../../store/entities/OZONE/validateImagenCampana";
import HistoryEditParticipacion from "../audit/history-editParticipacion.vue";
import Envio from "../../../store/entities/OZONE/envio";
import HistorialEnvios from '../envios/history.vue' 
import EditEnvio from "../envios/editarEnvio.vue"
import ControlDuplicadosDatoParticipacion from "../../../store/entities/duplicados/controlDuplicadosParticipacion"
import TipoFormaPagoCashback from "../../../store/entities/OZONE/tipoFormaPagoCashback";

class PageCamposRequest extends PageRequest {
  campanaId: number;
}
class EstadosRequest extends PageRequest {
  campanaId: number;
  participacionId: number;
}
class PaticipacionDatoRequest extends PageRequest {
  participacionId: number;
}
class PageHistoryRequest extends PageRequest {
  entityTypeFullName: string = "";
  entityId: string = "";
}

@Component({
  components: {
    Modal,
    BaseButton,
    BaseCheckbox,
    BaseDatePicker,
    SelectCampana,
    RuleCheckbox,
    FileAgent,
    EditImagenParticipacion,
    PeopleHeader,
    TransferenciaHistory,
    HistoryEditParticipacion,
    HistorialEnvios,
    EditEnvio
  },
})
export default class EditParticipacion extends AbpBase {
  name: "edit-participacion";
  // // @Prop({ type: Boolean, default: false }) value: boolean
  // // @Prop({ type: Boolean, default: false }) editing: boolean
  // // @Prop({ type: String }) id: string
  editing: boolean = false;
  participacion: Participacion = new Participacion();
  participacionDato: Array<ParticipacionDato> = [];
  participacionComentario: Array<ParticipacionComentario> = [];
  activeKey: Array<string> = ["1", "4", "5", "6"];
  title: String = "";
  sexo = [
    { label: "Hombre", key: "H" },
    { label: "Mujer", key: "M" },
  ];
  inputSexo: any = null;
  campana: Campana = new Campana();
  pageCampanaRequest: PageRequest = maxResults;
  pageCamposRequest: PageCamposRequest = new PageCamposRequest();
  estadosRequest: EstadosRequest = new EstadosRequest();
  pagePaticipacionDatoRequest: PaticipacionDatoRequest =
    new PaticipacionDatoRequest();
  rules: Array<any> = [];
  inputEstadoParticipacion: EstadoParticipacion = null;
  inputSubEstadoParticipacion: SubEstadoParticipacion = null;
  loading: boolean = false;
  auxLoading: boolean = false;
  subEstadoParticipacionList: Array<SubEstadoParticipacion> = [];
  transferencias: Array<Transferencia> = [];
  envios: Array<Envio> = [];
  fileRecordsTiket = [];
  canSelectImageTicket1: boolean = false;
  fileRecordsTiket2 = [];
  canSelectImageTicket2: boolean = false;
  fileRecordsEan = [];
  canSelectImageEan: boolean = false;
  fileRecordsDniFrontal = [];
  canSelectImageDniFrontal: boolean = false;
  fileRecordsDniTrasera = [];
  canSelectImageDniTrasera: boolean = false;
  fileRecordsAux = [];
  fileRecordsAux1 = [];
  fileRecordsAux2 = [];
  fileRecordsAux3 = [];
  fileRecordsAux4 = [];
  canSelectImageAux: boolean = false;
  canSelectImageAux1: boolean = false;
  canSelectImageAux2: boolean = false;
  canSelectImageAux3: boolean = false;
  canSelectImageAux4: boolean = false;
  editModalShowTiket: boolean = false;
  editModalShowTiket2: boolean = false;
  editModalShowEan: boolean = false;
  editModalShowDniFrontal: boolean = false;
  editModalShowDniTrasera: boolean = false;
  editModalShowAux: boolean = false;
  editModalShowAux1: boolean = false;
  editModalShowAux2: boolean = false;
  editModalShowAux3: boolean = false;
  editModalShowAux4: boolean = false;
  historialModalShow: boolean = false;
  selectedEnvio: number = null;
  readContentFile: any;
  disabled: boolean = false;
  newComment: string = null;
  imagesLoaded = false;
  imagesModified = false;
  validateImagenCampana: ValidateImagenCampana;
  OkImagenes: boolean = true;
  historyModalShow: boolean = false;
  entityId: number = 0;
  pagerequest: PageHistoryRequest = new PageHistoryRequest();
  pagination: any = {};
  envioInfo: Envio = null;
  isReenvio: boolean = false;
  editEnvioModalShow: boolean = false;
  controlDuplicadosDatoParticipacion = [];

  formInput(tipoDato) {
    switch (tipoDato) {
      case 0:
        return { comp: "base-input", type: "number", bind: "numerico" };
      case 1:
        return { comp: "base-input", type: "text", bind: "texto" };
      case 2:
        return {
          comp: "base-input",
          type: "number",
          bind: "decimal",
          step: "0.01",
        };
      case 3:
        return { comp: "base-checkbox", type: "checkbox", bind: "siNo" };
      case 4:
        return { comp: "base-date-picker", type: "date", bind: "fecha" };
      case 5:
        return { comp: "base-input", type: "text", bind: "url" };
      default:
        return "base-input";
    }
  }
  get tiposDePago() {
    let cuentas = this.$store.state.tipoFormaPagoCashback.list.map((e) => {
      return {
        label: e.nombre,
        value: e.id,
      };
    });
    return cuentas;
  }
  get historial() {
    return this.$store.state.audit.list;
  }
  get camposList() {
    return this.$store.state.campana.camposList;
  }

  get estadoParticipacionList() {
    if (this.participacion && this.participacion.campanaId) {
      return this.$store.state.estadoParticipacion.list;
    } else {
      return [];
    }
  }
  get pageSize() {
    return this.$store.state.audit.pageSize;
  }
  get totalCount() {
    return this.$store.state.audit.totalCount;
  }
  get currentPage() {
    return this.$store.state.audit.currentPage;
  }
  set currentPage(page) {
    this.$store.commit("audit/setCurrentPage", page);
  }

  async getHistorial() {
    this.pagerequest.maxResultCount = this.pageSize;
    this.pagerequest.skipCount = (this.currentPage - 1) * this.pageSize;
    this.pagerequest.entityTypeFullName = "OZONE.OZONE.Participacion";
    this.pagerequest.entityId =
      this.$store.state.participacion.editParticipacion.id;
    await this.$store.dispatch({
      type: "audit/getAll",
      data: this.pagerequest,
    });
    await this.$store.dispatch({
      type: "tipoFormaPagoCashback/getAll",
      data: this.pagerequest,
    });
    const pagination = { ...this.pagination };
    pagination.total = this.$store.state.audit.totalCount;
    pagination.pageSize = this.$store.state.audit.pageSize;
    pagination.current = this.$store.state.audit.currentPage;
    this.pagination = pagination;
  }
  async ticketFromImage(imageName) {
    if (this.participacion != null) {
      let index = this.participacion.ticket
        ? this.participacion.ticket.findIndex(
            (x) => x.imagenValidacion == imageName
          )
        : -1;
      if (index === -1) {
        await this.$store
          .dispatch({
            type: "participacion/getTicketDto",
            data: {
              participacionId: this.participacion.id,
              imagenValidacion: imageName,
            },
          })
          .then(() => {
            if (
              this.$store.state.participacion.editParticipacion.ticket.length >
              0
            ) {
              this.participacion.ticket = Util.extend(
                [{}],
                [{}],
                this.$store.state.participacion.editParticipacion.ticket
              );
            }
          });
      }
    }
  }
  async ticketFromEan(imageName) {
    if (this.participacion != null) {
      await this.$store
        .dispatch({
          type: "participacion/getTicketEanDto",
          data: {
            participacionId: this.participacion.id,
            imagenValidacion: imageName,
          },
        })
        .then(() => {
          this.$store.state.participacion.editParticipacion.eanTicket;
        });
    }
  }

  showHistorial(id){
    this.selectedEnvio = id;
    this.historialModalShow = true;
  }

  reenviarOActualizarEnvio(envio, isReenvio: boolean){
    this.envioInfo = envio;
    this.isReenvio = isReenvio;
    this.editEnvioModalShow = true;
  }

  reenvioOk(reenvio){
    if(this.isReenvio){
      if(reenvio && reenvio.id > 0){
        this.$store.commit("envio/addNewEnvio", reenvio);
        this.$message.success("Se ha realizado el reenvio correctamente", 5);
        this.getPage();
      }
      else{
        this.$message.error("No se ha podido realizar el reenvio", 5)
      }
    }
    else{
      this.$message.success("Se ha actualizado el envío correctamente", 5);
      this.getPage();
    }
  }

  async marcarUrgente(envio){
    this.loading = true;
    envio.urgente = true;
    await this.$store.dispatch({
      type: "envio/marcarEnvioUrgente",
      data: envio
    }).then(value => {
      if(value){
        this.$message.success("Envio marcado como urgente correctamente.", 5);
      }
      else{
        this.$message.error("No se ha podido marcar como urgente.", 5)
      }
    });
    
    this.loading = false;
  }

  makeRules(item, index) {
    this.rules[index] = "";
    if (item.listSelectedRules !== null) {
      const ruleCampo = JSON.parse(item.listSelectedRules);
      ruleCampo.forEach((rule) => {
        this.rules[index] = { ...this.rules[index], ...rule };
      });
    }
  }
  form: any;

  beforeCreate() {
    window.addEventListener("keydown", (e) => {
      if (e.key == "F10") {
      } else if (e.key == "F11") {
        this.save();
      } else if (e.key == "F12") {
      } else if (e.key == "F9") {
        this.close();
      }
    });
    this.form = this.$form.createForm(this, { name: "participacionForm" });
  }

  async onSubmit() {
    this.form.validateFields(async (err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        await this.save();
      } else {
        console.log(err);
      }
    });
  }

  handleChange(e) {
    this.newComment = e.target.value;
  }

  async addComment() {
    if (!this.newComment) {
      return;
    }
    await this.$store
      .dispatch({
        type: "participacion/postComentario",
        data: {
          participacionId: this.$store.state.participacion.editParticipacion.id,
          comentario: this.newComment,
        },
      })
      .then(async () => {
        await this.getComentarios();
      });
  }

  async delComment(com) {
    await this.$store
      .dispatch({
        type: "participacion/deleteComentario",
        data: { id: com.id },
      })
      .then(async () => {
        await this.getComentarios();
      });
  }

  async save() {
    this.loading = true;
    this.OkImagenes = true;
    if (this.validateImagenCampana != null) {
      this.saveTiket();
      this.saveTiket2();
      this.saveEan();
      this.saveDniFrontal();
      this.saveDniTrasera();
      this.saveAux();
      this.saveAux1();
      this.saveAux2();
      this.saveAux3();
      this.saveAux4();
    }

    if (this.OkImagenes) {
      this.participacion.campanaId = this.campana.id;
      this.participacion.sexo = this.inputSexo != null ? this.inputSexo.key : null;
      this.participacion.estadoParticipacion = this.inputEstadoParticipacion;
      this.participacion.estadoParticipacionId =
        this.inputEstadoParticipacion != null
          ? this.inputEstadoParticipacion.id
          : null;
      this.participacion.subEstadoParticipacion =
        this.inputSubEstadoParticipacion;
      this.participacion.subEstadoParticipacionId =
        this.inputSubEstadoParticipacion != null
          ? this.inputSubEstadoParticipacion.id
          : null;
      this.participacion.imagesModified = this.imagesModified;

      if (this.editing) {
        await this.$store
          .dispatch({
            type: "participacionDato/createOrEditList",
            data: this.participacionDato,
          })
          .then(async () => {
            await this.$store
              .dispatch({
                type: "participacion/update",
                data: this.participacion,
              })
              .then(() => this.close());
          });
      } else {
        this.participacion.participacionDato = this.participacionDato;
        await this.$store
          .dispatch({
            type: "participacion/create",
            data: this.participacion,
          })
          .then(() => this.close());
      }
    } else {
      this.loading = false;
    }
  }

  close() {
    this.resetFields();
    this.loading = false;
    this.$router.go(-1);
  }

  async mounted() {
    await this.getPage();
  }

  async getPage(){
    this.loading = true;
    this.editing = this.$route.params.id != null;
    if (this.editing) {
      await this.$store
        .dispatch({
          type: "participacion/get",
          id: this.$route.params.id,
        })
        .then(() => {
          this.participacion = Util.extend(
            true,
            {},
            this.$store.state.participacion.editParticipacion
          );
          this.getCampana();
          this.getvalidateImagenCampana();
          this.getEstadoParticipacion();
          this.getComentarios();
          this.getHistorial();
        });
      this.inputSexo = this.sexo.find((x) => x.key == this.participacion.sexo);
      this.title = this.L("Editar Participacion");
      this.transferencias =
        this.$store.state.participacion.editParticipacion.transferencias;
      this.envios = this.$store.state.participacion.editParticipacion.envios;
      
    } else {
      this.getHistorial();
      if (this.$route.params.campanaId != null) {
        this.participacion.campanaId = +this.$route.params.campanaId;
        this.getCampana();
        this.$store.commit("participacion/edit", this.participacion);
        this.getEstadoParticipacion();
        this.title = this.L("Añadir Participacion");
      } else {
        this.$store.commit("participacion/edit", new Participacion());
        this.title = this.L("Añadir participacion");
      }
    }

    this.pageCamposRequest.campanaId = this.participacion.campanaId;
    this.pageCamposRequest.maxResultCount = maxResults.maxResultCount;
    await this.$store
      .dispatch({
        type: "campana/getAllCampos",
        data: this.pageCamposRequest,
      })
      .then(async () => {
        await this.$store
          .dispatch({
            type: "campana/GetAllReglasCampo",
            data: { campanaId: this.participacion.campanaId },
          })
          .then((result) => {
            result.map((x) => {
              if (x.campoId != null) {
                this.$store.commit("campana/addCampoRule", x);
              }
            });
          });
      });
    this.pagePaticipacionDatoRequest.participacionId = this.participacion.id;
    await this.$store.dispatch({
      type: "participacionDato/getAll",
      data: this.pagePaticipacionDatoRequest,
    });
    this.participacionDato = this.$store.state.participacionDato.list;
    this.createParticipacionDatoIfNeeded();
    if (this.editing) {
      this.$nextTick(() => {
        const valid = (this.$refs.observer as any).validate();
      });
    }
    this.loading = false;
    await this.openImages();
  }
  //si no está el dato del campo lo creamos
  createParticipacionDatoIfNeeded() {
    let result = this.camposList.filter(
      (o1) => !this.participacionDato.some((o2) => o1.id === o2.campoId)
    );
    if (result) {
      result.forEach((element) => {
        let participacionDato = new ParticipacionDato();
        participacionDato.campoId = element.id;
        participacionDato.participacionId = this.participacion.id;
        this.participacionDato.push(participacionDato);
      });
    }
  }

  async getvalidateImagenCampana() {
    if (
      this.$store.state.participacion.editParticipacion.campanaId != null ||
      this.$route.params.campanaId != null ||
      this.campana.id != null
    ) {
      await this.$store.dispatch({
        type: "campana/getvalidateImagenCampana",
        id: this.editing ? this.$store.state.participacion.editParticipacion.campanaId : this.campana.id
      }).then((validateImagenCampana) => {
        this.validateImagenCampana = validateImagenCampana;
      });
    }

    this.showSelectedImagesToValidate();
  }

  showSelectedImagesToValidate(){
    if(this.validateImagenCampana){
      this.canSelectImageTicket1 = this.validateImagenCampana.checkedTicket1;
      this.canSelectImageTicket2 = this.validateImagenCampana.checkedTicket2;
      this.canSelectImageEan = this.validateImagenCampana.checkedEan;
      this.canSelectImageDniFrontal = this.validateImagenCampana.checkedDNIFrontal;
      this.canSelectImageDniTrasera = this.validateImagenCampana.checkedDNITrasero;
      this.canSelectImageAux = this.validateImagenCampana.checkedImagenAuxiliar;
      this.canSelectImageAux1 = this.validateImagenCampana.checkedImagenAuxiliar1;
      this.canSelectImageAux2 = this.validateImagenCampana.checkedImagenAuxiliar2
      this.canSelectImageAux3 = this.validateImagenCampana.checkedImagenAuxiliar3
      this.canSelectImageAux4 = this.validateImagenCampana.checkedImagenAuxiliar4  
    }
    else{
      this.canSelectImageTicket1 = this.canSelectImageTicket2 = this.canSelectImageEan = 
      this.canSelectImageDniFrontal = this.canSelectImageDniTrasera =  this.canSelectImageAux = 
      this.canSelectImageAux1 = this.canSelectImageAux2 = this.canSelectImageAux3 = this.canSelectImageAux4 = false; 
    }
  }

  async getCampana() {
    await this.$store
      .dispatch({
          type: "campana/getForView",
        id: this.participacion.campanaId,
      })
      .then((campana) => {
        this.campana = campana;
      });
  }

  async getComentarios() {
    await this.$store
      .dispatch({
        type: "participacion/getComentario",
        id: this.participacion.id,
      })
      .then((coments) => {
        this.participacionComentario = coments;
      });
  }

  async changeCampana(campana) {
    this.pageCamposRequest.campanaId = campana.key;
    await this.$store.dispatch({
      type: "campana/getAllCampos",
      data: this.pageCamposRequest,
    });

    this.participacion.campanaId = campana.id;
    this.clearCampos();
    this.participacionDato = [];
    this.createParticipacionDatoIfNeeded()
    await this.getEstadoParticipacion();
    await this.getvalidateImagenCampana();

  }

  async getEstadoParticipacion() {
    
    if (this.participacion.campanaId) {
      this.estadosRequest.campanaId = this.participacion.campanaId;
      this.estadosRequest.participacionId = this.participacion.id;
      await this.$store
        .dispatch({
          type: "estadoParticipacion/getAllByCampana",
          data: this.estadosRequest,
        })
        .then(() => {
          
          this.inputEstadoParticipacion = this.estadoParticipacionList.find(
            (x) => x.id == this.participacion.estadoParticipacionId
          );
          this.inputSubEstadoParticipacion =
            this.participacion.subEstadoParticipacionId != null
              ? this.inputEstadoParticipacion.subEstadoParticipaciones.find(
                  (x) => x.id == this.participacion.subEstadoParticipacionId
                )
              : null;
          this.setDisabled();
        });
    }
  }

  @Watch("inputEstadoParticipacion")
  async selectEstadoParticipacionChange(value) {
    if (value && value !== null) {
      this.subEstadoParticipacionList = value.subEstadoParticipaciones;
      if (
        this.participacion.subEstadoParticipacionId != null &&
        this.subEstadoParticipacionList.find(
          (x) => x.id == this.participacion.subEstadoParticipacionId
        )
      ) {
        this.inputSubEstadoParticipacion = this.subEstadoParticipacionList.find(
          (x) => x.id == this.participacion.subEstadoParticipacionId
        );
      } else {
        this.inputSubEstadoParticipacion = null;
      }
    }
  }
  setDisabled() {
    if (
      !abp.auth.isAnyGranted(
        "OZONE.Participaciones.Edit",
        "OZONE.Participaciones.Create",
        "OZONE.Participaciones.Delete"
      )
    ) {
      this.disabled = true;
    } else if (this.inputEstadoParticipacion) {
      this.disabled =
        !abp.auth.isGranted("OZONE.Participaciones.Admin") &&
        this.inputEstadoParticipacion.estadoFinal;
    } else {
      this.disabled =
        !abp.auth.isGranted("OZONE.Participaciones.Admin") &&
        !(this.participacion.id !== 0);
    }
  }

  clearCampos() {
    this.participacionDato.forEach((element) => {
      element.numerico = null;
      element.texto = null;
      element.decimal = null;
      element.siNo = null;
      element.fecha = null;
      element.url = null;
    });
  }
  checkFormValidity() {
    return true;
    alert();
    const valid = (this.$refs.participacionForm as any).checkValidity();
    console.log(valid);
    return valid;
  }

  resetFields() {
    this.participacion = new Participacion();
    this.inputEstadoParticipacion = null;
    this.inputSubEstadoParticipacion = null;
    this.fileRecordsTiket = [];
    this.fileRecordsTiket2 = [];
    this.fileRecordsEan = [];
    this.fileRecordsDniFrontal = [];
    this.fileRecordsDniTrasera = [];
    this.fileRecordsAux = [];
    this.fileRecordsAux1 = [];
    this.fileRecordsAux2 = [];
    this.fileRecordsAux3 = [];
    this.fileRecordsAux4 = [];
  }

  async openImages() {
    if (!this.imagesLoaded && this.participacion.id) {
      await this.$store
        .dispatch({
          type: "participacion/getImages",
          id: this.participacion.id,
        })
        .then(async (x) => {
          this.participacion.validacionFileContent = x.participacion.validacionFileContent;
          this.participacion.validacion2FileContent = x.participacion.validacion2FileContent;
          this.participacion.eanFileContent = x.participacion.eanFileContent;
          this.participacion.dniFrontalFileContent = x.participacion.dniFrontalFileContent;
          this.participacion.dniTraseraFileContent = x.participacion.dniTraseraFileContent;
          this.participacion.fromFileFileContent = x.participacion.fromFileFileContent;
          this.participacion.imagenAuxiliar1FileContent = x.participacion.imagenAuxiliar1FileContent;
          this.participacion.imagenAuxiliar2FileContent = x.participacion.imagenAuxiliar2FileContent;
          this.participacion.imagenAuxiliar3FileContent = x.participacion.imagenAuxiliar3FileContent;
          this.participacion.imagenAuxiliar4FileContent = x.participacion.imagenAuxiliar4FileContent;
          this.getTiket();
          this.getTiket2();
          this.getEan();
          this.getDniFrontal();
          this.getDniTrasera();
          this.getAux();
          this.getAux1();
          this.getAux2();
          this.getAux3();
          this.getAux4();
          this.imagesLoaded = true;
        });
    }
    if (!this.editing) {
      this.imagesLoaded = true;
    }
  }
  setImagesModified() {
    this.imagesModified = true;
  }

  getTiket() {
    if (
      this.participacion.validacion &&
      this.participacion.validacionFileContent
    ) {
      var image = {
        name: this.participacion.validacion,
        lastModified: 0,
        file: this.dataURLtoFile(
          `data:image/png;base64,${this.participacion.validacionFileContent}`,
          this.participacion.validacion
        ),
        sizeText: "549 KB",
        size: this.participacion.validacionFileContent.length,
        type: "image/*",
        dimensions: {
          width: 640,
          height: 360,
        },
        urlResized: `data:image/png;base64,${this.participacion.validacionFileContent}`,
        url: `data:image/png;base64,${this.participacion.validacionFileContent}`,
      };
      this.fileRecordsTiket = [];
      this.fileRecordsTiket.push(image);
      this.ticketFromImage(image.name);
    }
  }

  getTiket2() {
    if (
      this.participacion.validacion2 &&
      this.participacion.validacion2FileContent
    ) {
      var image = {
        name: this.participacion.validacion2,
        lastModified: 0,
        file: this.dataURLtoFile(
          `data:image/png;base64,${this.participacion.validacion2FileContent}`,
          this.participacion.validacion2
        ),
        sizeText: "549 KB",
        size: this.participacion.validacion2FileContent.length,
        type: "image/*",
        dimensions: {
          width: 640,
          height: 360,
        },
        urlResized: `data:image/png;base64,${this.participacion.validacion2FileContent}`,
        url: `data:image/png;base64,${this.participacion.validacion2FileContent}`,
      };
      this.fileRecordsTiket2 = [];
      this.fileRecordsTiket2.push(image);
      this.ticketFromImage(image.name);
    }
  }
  getEan() {
    if (this.participacion.ean && this.participacion.eanFileContent) {
      var image = {
        name: this.participacion.ean,
        lastModified: 0,
        file: this.dataURLtoFile(
          `data:image/png;base64,${this.participacion.eanFileContent}`,
          this.participacion.ean
        ),
        sizeText: "549 KB",
        size: this.participacion.eanFileContent.length,
        type: "image/*",
        dimensions: {
          width: 640,
          height: 360,
        },
        urlResized: `data:image/png;base64,${this.participacion.eanFileContent}`,
        url: `data:image/png;base64,${this.participacion.eanFileContent}`,
      };
      this.fileRecordsEan = [];
      this.fileRecordsEan.push(image);
      this.ticketFromEan(image.name);
    }
  }
  getDniFrontal() {
    if (
      this.participacion.dniFrontal &&
      this.participacion.dniFrontalFileContent
    ) {
      var image = {
        name: this.participacion.dniFrontal,
        lastModified: 0,
        file: this.dataURLtoFile(
          `data:image/png;base64,${this.participacion.dniFrontalFileContent}`,
          this.participacion.dniFrontal
        ),
        sizeText: "549 KB",
        size: this.participacion.dniFrontalFileContent.length,
        type: "image/*",
        dimensions: {
          width: 640,
          height: 360,
        },
        urlResized: `data:image/png;base64,${this.participacion.dniFrontalFileContent}`,
        url: `data:image/png;base64,${this.participacion.dniFrontalFileContent}`,
      };
      this.fileRecordsDniFrontal = [];
      this.fileRecordsDniFrontal.push(image);
      //this.ticketFromImage(image.name);
    }
  }
  getDniTrasera() {
    if (
      this.participacion.dniTrasera &&
      this.participacion.dniTraseraFileContent
    ) {
      var image = {
        name: this.participacion.dniTrasera,
        lastModified: 0,
        file: this.dataURLtoFile(
          `data:image/png;base64,${this.participacion.dniTraseraFileContent}`,
          this.participacion.dniTrasera
        ),
        sizeText: "549 KB",
        size: this.participacion.dniTraseraFileContent.length,
        type: "image/*",
        dimensions: {
          width: 640,
          height: 360,
        },
        urlResized: `data:image/png;base64,${this.participacion.dniTraseraFileContent}`,
        url: `data:image/png;base64,${this.participacion.dniTraseraFileContent}`,
      };
      this.fileRecordsDniTrasera = [];
      this.fileRecordsDniTrasera.push(image);
      //this.ticketFromImage(image.name);
    }
  }
  getAux() {
    if (this.participacion.fromFile && this.participacion.fromFileFileContent) {
      var image = {
        name: this.participacion.fromFile,
        lastModified: 0,
        file: this.dataURLtoFile(
          `data:image/png;base64,${this.participacion.fromFileFileContent}`,
          this.participacion.fromFile
        ),
        sizeText: "549 KB",
        size: this.participacion.fromFileFileContent.length,
        type: "image/*",
        dimensions: {
          width: 640,
          height: 360,
        },
        urlResized: `data:image/png;base64,${this.participacion.fromFileFileContent}`,
        url: `data:image/png;base64,${this.participacion.fromFileFileContent}`,
      };
      this.fileRecordsAux = [];
      this.fileRecordsAux.push(image);
      //this.ticketFromImage(image.name);
    }
  }
  getAux1() {
    if (this.participacion.imagenAuxiliar1 && this.participacion.imagenAuxiliar1FileContent) {
      var image = {
        name: this.participacion.imagenAuxiliar1,
        lastModified: 0,
        file: this.dataURLtoFile(
          `data:image/png;base64,${this.participacion.imagenAuxiliar1FileContent}`,
          this.participacion.imagenAuxiliar1
        ),
        sizeText: "549 KB",
        size: this.participacion.imagenAuxiliar1FileContent.length,
        type: "image/*",
        dimensions: {
          width: 640,
          height: 360,
        },
        urlResized: `data:image/png;base64,${this.participacion.imagenAuxiliar1FileContent}`,
        url: `data:image/png;base64,${this.participacion.imagenAuxiliar1FileContent}`,
      };
      this.fileRecordsAux1 = [];
      this.fileRecordsAux1.push(image);
      //this.ticketFromImage(image.name);
    }
  }
  getAux2() {
    if (this.participacion.imagenAuxiliar2 && this.participacion.imagenAuxiliar2FileContent) {
      var image = {
        name: this.participacion.imagenAuxiliar2,
        lastModified: 0,
        file: this.dataURLtoFile(
          `data:image/png;base64,${this.participacion.imagenAuxiliar2FileContent}`,
          this.participacion.imagenAuxiliar2
        ),
        sizeText: "549 KB",
        size: this.participacion.imagenAuxiliar2FileContent.length,
        type: "image/*",
        dimensions: {
          width: 640,
          height: 360,
        },
        urlResized: `data:image/png;base64,${this.participacion.imagenAuxiliar2FileContent}`,
        url: `data:image/png;base64,${this.participacion.imagenAuxiliar2FileContent}`,
      };
      this.fileRecordsAux2 = [];
      this.fileRecordsAux2.push(image);
      //this.ticketFromImage(image.name);
    }
  }
  getAux3() {
    if (this.participacion.imagenAuxiliar3 && this.participacion.imagenAuxiliar3FileContent) {
      var image = {
        name: this.participacion.imagenAuxiliar3,
        lastModified: 0,
        file: this.dataURLtoFile(
          `data:image/png;base64,${this.participacion.imagenAuxiliar3FileContent}`,
          this.participacion.imagenAuxiliar3
        ),
        sizeText: "549 KB",
        size: this.participacion.imagenAuxiliar3FileContent.length,
        type: "image/*",
        dimensions: {
          width: 640,
          height: 360,
        },
        urlResized: `data:image/png;base64,${this.participacion.imagenAuxiliar3FileContent}`,
        url: `data:image/png;base64,${this.participacion.imagenAuxiliar3FileContent}`,
      };
      this.fileRecordsAux3 = [];
      this.fileRecordsAux3.push(image);
      //this.ticketFromImage(image.name);
    }
  }
  getAux4() {
    if (this.participacion.imagenAuxiliar4 && this.participacion.imagenAuxiliar4FileContent) {
      var image = {
        name: this.participacion.imagenAuxiliar4,
        lastModified: 0,
        file: this.dataURLtoFile(
          `data:image/png;base64,${this.participacion.imagenAuxiliar4FileContent}`,
          this.participacion.imagenAuxiliar4
        ),
        sizeText: "549 KB",
        size: this.participacion.imagenAuxiliar4FileContent.length,
        type: "image/*",
        dimensions: {
          width: 640,
          height: 360,
        },
        urlResized: `data:image/png;base64,${this.participacion.imagenAuxiliar4FileContent}`,
        url: `data:image/png;base64,${this.participacion.imagenAuxiliar4FileContent}`,
      };
      this.fileRecordsAux4 = [];
      this.fileRecordsAux4.push(image);
      //this.ticketFromImage(image.name);
    }
  }

  async editTiket() {
    if (this.fileRecordsTiket[0] && this.fileRecordsTiket[0].file) {
      this.readFile(this.fileRecordsTiket[0].file).then((x) => {
        this.fileRecordsTiket[0].url = x;
        this.editModalShowTiket = true;
      });
    }
  }
  async editTiket2() {
    if (this.fileRecordsTiket2[0] && this.fileRecordsTiket2[0].file) {
      this.readFile(this.fileRecordsTiket2[0].file).then((x) => {
        this.fileRecordsTiket2[0].url = x;
        this.editModalShowTiket2 = true;
      });
    }
  }
  async editEan() {
    if (this.fileRecordsEan[0] && this.fileRecordsEan[0].file) {
      this.readFile(this.fileRecordsEan[0].file).then((x) => {
        this.fileRecordsEan[0].url = x;
        this.editModalShowEan = true;
      });
    }
  }
  async editDniFrontal() {
    if (this.fileRecordsDniFrontal[0] && this.fileRecordsDniFrontal[0].file) {
      this.readFile(this.fileRecordsDniFrontal[0].file).then((x) => {
        this.fileRecordsDniFrontal[0].url = x;
        this.editModalShowDniFrontal = true;
      });
    }
  }
  async editDniTrasera() {
    if (this.fileRecordsDniTrasera[0] && this.fileRecordsDniTrasera[0].file) {
      this.readFile(this.fileRecordsDniTrasera[0].file).then((x) => {
        this.fileRecordsDniTrasera[0].url = x;
        this.editModalShowDniTrasera = true;
      });
    }
  }
  async editAux() {
    if (this.fileRecordsAux[0] && this.fileRecordsAux[0].file) {
      this.readFile(this.fileRecordsAux[0].file).then((x) => {
        this.fileRecordsAux[0].url = x;
        this.editModalShowAux = true;
      });
    }
  }
  async editAux1() {
    if (this.fileRecordsAux1[0] && this.fileRecordsAux1[0].file) {
      this.readFile(this.fileRecordsAux1[0].file).then((x) => {
        this.fileRecordsAux1[0].url = x;
        this.editModalShowAux1 = true;
      });
    }
  }
  async editAux2() {
    if (this.fileRecordsAux2[0] && this.fileRecordsAux2[0].file) {
      this.readFile(this.fileRecordsAux2[0].file).then((x) => {
        this.fileRecordsAux2[0].url = x;
        this.editModalShowAux2 = true;
      });
    }
  }
  async editAux3() {
    if (this.fileRecordsAux3[0] && this.fileRecordsAux3[0].file) {
      this.readFile(this.fileRecordsAux3[0].file).then((x) => {
        this.fileRecordsAux3[0].url = x;
        this.editModalShowAux3 = true;
      });
    }
  }
  async editAux4() {
    if (this.fileRecordsAux4[0] && this.fileRecordsAux4[0].file) {
      this.readFile(this.fileRecordsAux4[0].file).then((x) => {
        this.fileRecordsAux4[0].url = x;
        this.editModalShowAux4 = true;
      });
    }
  }

  readFile(file) {
    return new Promise((resolve) => {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.readContentFile = e.target.result;
        resolve(this.readContentFile);
      };
      reader.readAsDataURL(file);
      reader.result;
    });
  }

  async SaveTicketToImage(imageName, ticket) {
    ticket.imagenValidacion = imageName;
    if (!this.participacion.ticket) {
      this.participacion.ticket = [];
      this.participacion.ticket.push(ticket);
    } else {
      let index = this.participacion.ticket
        ? this.participacion.ticket.findIndex(
            (x) => x.imagenValidacion == imageName
          )
        : -1;
      if (index === -1) {
        this.participacion.ticket.push(ticket);
      } else {
        this.participacion.ticket.splice(index, 1);

        this.participacion.ticket.push(ticket);
      }
    }
    this.$store.commit("participacion/saveTicket", this.participacion.ticket);
  }
  async SaveTicketEan(imageName, eanTicket) {
    eanTicket.imagenValidacion = imageName;
    if (!this.participacion.eanTicket) {
      this.participacion.eanTicket = [];
      this.participacion.eanTicket.push(eanTicket);
    }

    this.$store.commit("participacion/saveEan", eanTicket);

    //  this.$store.state.participacionDato.list.forEach(element => {
    //   if(element.campoId == 43){
    //     element.
    //     this.$store.state.participacionDato= this.participacion.ticket[0].items[0].unit_price

    //   }
    // });
  }

  savesuccessTiket(imagen, ticket) {
    var image = {
      name: this.fileRecordsTiket[0].file.name,
      lastModified: 0,
      file: this.dataURLtoFile(imagen, this.fileRecordsTiket[0].file.name),
      sizeText: "549 KB",
      size: imagen.length,
      type: "image/*",
      dimensions: {
        width: 640,
        height: 360,
      },
      urlResized: imagen,
      url: imagen,
    };
    this.fileRecordsTiket = [];
    this.fileRecordsTiket.push(image);
    if (ticket !== undefined) {
      this.SaveTicketToImage(this.fileRecordsTiket[0].file.name, ticket);
    }
  }

  savesuccessTiket2(imagen, ticket) {
    var image = {
      name: this.fileRecordsTiket2[0].file.name,
      lastModified: 0,
      file: this.dataURLtoFile(imagen, this.fileRecordsTiket2[0].file.name),
      sizeText: "549 KB",
      size: imagen.length,
      type: "image/*",
      dimensions: {
        width: 640,
        height: 360,
      },
      urlResized: imagen,
      url: imagen,
    };
    this.fileRecordsTiket2 = [];
    this.fileRecordsTiket2.push(image);
    if (ticket !== undefined) {
      this.SaveTicketToImage(this.fileRecordsTiket2[0].file.name, ticket);
    }
  }

  savesuccessEan(imagen, eanTicket) {
    var image = {
      name: this.fileRecordsEan[0].file.name,
      lastModified: 0,
      file: this.dataURLtoFile(imagen, this.fileRecordsEan[0].file.name),
      sizeText: "549 KB",
      size: imagen.length,
      type: "image/*",
      dimensions: {
        width: 640,
        height: 360,
      },
      urlResized: imagen,
      url: imagen,
    };
    this.fileRecordsEan = [];
    this.fileRecordsEan.push(image);
    if (eanTicket !== undefined) {
      this.SaveTicketEan(this.fileRecordsEan[0].file.name, eanTicket);
    }
  }
  
  savesuccessDniFrontal(imagen, ticket) {
    var image = {
      name: this.fileRecordsDniFrontal[0].file.name,
      lastModified: 0,
      file: this.dataURLtoFile(imagen, this.fileRecordsDniFrontal[0].file.name),
      sizeText: "549 KB",
      size: imagen.length,
      type: "image/*",
      dimensions: {
        width: 640,
        height: 360,
      },
      urlResized: imagen,
      url: imagen,
    };
    this.fileRecordsDniFrontal = [];
    this.fileRecordsDniFrontal.push(image);
    if (ticket !== undefined) {
      this.SaveTicketToImage(this.fileRecordsDniFrontal[0].file.name, ticket);
    }
  }

  savesuccessDniTrasera(imagen, ticket) {
    var image = {
      name: this.fileRecordsDniTrasera[0].file.name,
      lastModified: 0,
      file: this.dataURLtoFile(imagen, this.fileRecordsDniTrasera[0].file.name),
      sizeText: "549 KB",
      size: imagen.length,
      type: "image/*",
      dimensions: {
        width: 640,
        height: 360,
      },
      urlResized: imagen,
      url: imagen,
    };
    this.fileRecordsDniTrasera = [];
    this.fileRecordsDniTrasera.push(image);
    if (ticket !== undefined) {
      this.SaveTicketToImage(this.fileRecordsDniTrasera[0].file.name, ticket);
    }
  }
  
  savesuccessAux(imagen, ticket) {
    var image = {
      name: this.fileRecordsAux[0].file.name,
      lastModified: 0,
      file: this.dataURLtoFile(imagen, this.fileRecordsAux[0].file.name),
      sizeText: "549 KB",
      size: imagen.length,
      type: "image/*",
      dimensions: {
        width: 640,
        height: 360,
      },
      urlResized: imagen,
      url: imagen,
    };
    this.fileRecordsAux = [];
    this.fileRecordsAux.push(image);
    if (ticket !== undefined) {
      this.SaveTicketToImage(this.fileRecordsAux[0].file.name, ticket);
    }
  }
  savesuccessAux1(imagen, ticket) {
    var image = {
      name: this.fileRecordsAux1[0].file.name,
      lastModified: 0,
      file: this.dataURLtoFile(imagen, this.fileRecordsAux1[0].file.name),
      sizeText: "549 KB",
      size: imagen.length,
      type: "image/*",
      dimensions: {
        width: 640,
        height: 360,
      },
      urlResized: imagen,
      url: imagen,
    };
    this.fileRecordsAux1 = [];
    this.fileRecordsAux1.push(image);
    if (ticket !== undefined) {
      this.SaveTicketToImage(this.fileRecordsAux1[0].file.name, ticket);
    }
  }
  savesuccessAux2(imagen, ticket) {
    var image = {
      name: this.fileRecordsAux2[0].file.name,
      lastModified: 0,
      file: this.dataURLtoFile(imagen, this.fileRecordsAux2[0].file.name),
      sizeText: "549 KB",
      size: imagen.length,
      type: "image/*",
      dimensions: {
        width: 640,
        height: 360,
      },
      urlResized: imagen,
      url: imagen,
    };
    this.fileRecordsAux2 = [];
    this.fileRecordsAux2.push(image);
    if (ticket !== undefined) {
      this.SaveTicketToImage(this.fileRecordsAux2[0].file.name, ticket);
    }
  }
  savesuccessAux3(imagen, ticket) {
    var image = {
      name: this.fileRecordsAux3[0].file.name,
      lastModified: 0,
      file: this.dataURLtoFile(imagen, this.fileRecordsAux3[0].file.name),
      sizeText: "549 KB",
      size: imagen.length,
      type: "image/*",
      dimensions: {
        width: 640,
        height: 360,
      },
      urlResized: imagen,
      url: imagen,
    };
    this.fileRecordsAux3 = [];
    this.fileRecordsAux3.push(image);
    if (ticket !== undefined) {
      this.SaveTicketToImage(this.fileRecordsAux3[0].file.name, ticket);
    }
  }
  savesuccessAux4(imagen, ticket) {
    var image = {
      name: this.fileRecordsAux4[0].file.name,
      lastModified: 0,
      file: this.dataURLtoFile(imagen, this.fileRecordsAux4[0].file.name),
      sizeText: "549 KB",
      size: imagen.length,
      type: "image/*",
      dimensions: {
        width: 640,
        height: 360,
      },
      urlResized: imagen,
      url: imagen,
    };
    this.fileRecordsAux4 = [];
    this.fileRecordsAux4.push(image);
    if (ticket !== undefined) {
      this.SaveTicketToImage(this.fileRecordsAux4[0].file.name, ticket);
    }
  }

  prev() {
    var index =
      this.$store.state.participacion.list
        .map(function (x) {
          return x.id;
        })
        .indexOf(this.$route.params.id) - 1;
    alert(index);
    if (index >= 0 && index < this.$store.state.participacion.list.length - 1) {
      let p = this.$store.state.participacion.list[index];
      this.$router.push({ name: "editparticipacion", params: { id: p.id } });
      location.reload();
    }
  }

  next() {
    var index =
      this.$store.state.participacion.list
        .map(function (x) {
          return x.id;
        })
        .indexOf(this.$route.params.id) + 1;
    alert(index);

    if (index >= 0 && index < this.$store.state.participacion.list.length - 1) {
      let p = this.$store.state.participacion.list[index];
      this.$router.push({ name: "editparticipacion", params: { id: p.id } });
      location.reload();
    }
  }

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  saveTiket() {
    if(!this.validateImagenCampana.checkedTicket1 ){
      return;
    }

    if (this.fileRecordsTiket.length <= 0) {
      //this.$message.error("La imagen del Ticket 1 es obligatoria", 5);
      this.participacion.validacion = null;
      this.participacion.validacionFileContent = null;       
      //this.OkImagenes = false;
      return;
    } 

    this.participacion.validacion = this.fileRecordsTiket[0].name();
    if (this.fileRecordsTiket[0].lastModified === 0) {
      this.participacion.validacionFileContent = this.fileRecordsTiket[0].url;
    } 
    else {
      this.readFile(this.fileRecordsTiket[0].file).then((x) => {
        this.participacion.validacionFileContent = x as string;
      });
      this.participacion.validacionFileContent =
        this.fileRecordsTiket[0].urlResized;
    }
  }

  saveTiket2() {
    if(!this.validateImagenCampana.checkedTicket2){
      return;
    }
    
    if (this.fileRecordsTiket2.length <= 0) {
      //this.$message.error("La imagen del Ticket 2 es obligatoria", 5);
      this.participacion.validacion2 = null;
      this.participacion.validacion2FileContent = null;
      //this.OkImagenes = false;
      return;
    } 

    this.participacion.validacion2 = this.fileRecordsTiket2[0].name();
    if (this.fileRecordsTiket2[0].lastModified === 0) {
      this.participacion.validacion2FileContent =
        this.fileRecordsTiket2[0].url;
    } else {
      this.readFile(this.fileRecordsTiket2[0].file).then((x) => {
        this.participacion.validacion2FileContent = x as string;
      });
      this.participacion.validacion2FileContent =
        this.fileRecordsTiket2[0].urlResized;
    }
  }
  
  saveEan() {
    if(!this.validateImagenCampana.checkedEan){
      return;
    }

    if (this.fileRecordsEan.length <= 0) {
      //this.$message.error("La imagen del Ean es obligatoria", 5);
      this.participacion.ean = null;
      this.participacion.eanFileContent = null;
      //this.OkImagenes = false;
      return;
    } 

    this.participacion.ean = this.fileRecordsEan[0].name();
    if (this.fileRecordsEan[0].lastModified === 0) {
      this.participacion.eanFileContent = this.fileRecordsEan[0].url;
    } else {
      this.readFile(this.fileRecordsEan[0].file).then((x) => {
        this.participacion.eanFileContent = x as string;
      });
    }
    this.participacion.eanFileContent = this.fileRecordsEan[0].urlResized;
    this.participacion.eanTicket =
      this.$store.state.participacion.editParticipacion.eanTicket;
  }

  saveDniFrontal() {
    if(!this.validateImagenCampana.checkedDNIFrontal){
      return;
    }

    if (this.fileRecordsDniFrontal.length <= 0) {
      //this.$message.error("La imagen del DNI Frontal es obligatoria", 5);
      this.participacion.dniFrontal = null;
      this.participacion.dniFrontalFileContent = null;
      //this.OkImagenes = false;
      return;
    } 
  
    this.participacion.dniFrontal = this.fileRecordsDniFrontal[0].name();
    if (this.fileRecordsDniFrontal[0].lastModified === 0) {
      this.participacion.dniFrontalFileContent =
        this.fileRecordsDniFrontal[0].url;
    } else {
      this.readFile(this.fileRecordsDniFrontal[0].file).then((x) => {
        this.participacion.dniFrontalFileContent = x as string;
      });
    }
    this.participacion.dniFrontalFileContent =
      this.fileRecordsDniFrontal[0].urlResized;
  }

  saveDniTrasera() {
    if(!this.validateImagenCampana.checkedDNITrasero){
      return;
    }
      
    if (this.fileRecordsDniTrasera.length <= 0) {
      //this.$message.error("La imagen del DNI Trasero es obligatoria", 5);
      this.participacion.dniTrasera = null;
      this.participacion.dniTraseraFileContent = null;
      //this.OkImagenes = false;
      return;
    } 

    this.participacion.dniTrasera = this.fileRecordsDniTrasera[0].name();
    if (this.fileRecordsDniTrasera[0].lastModified === 0) {
      this.participacion.dniTraseraFileContent =
        this.fileRecordsDniTrasera[0].url;
    } else {
      this.readFile(this.fileRecordsDniTrasera[0].file).then((x) => {
        this.participacion.dniTraseraFileContent = x as string;
      });
    }
    this.participacion.dniTraseraFileContent =
      this.fileRecordsDniTrasera[0].urlResized;
  }

  saveAux() {
    if(!this.validateImagenCampana.checkedImagenAuxiliar){
      return;
    }

    if (this.fileRecordsAux.length <= 0) {
      //this.$message.error("La imagen del Ticket Auxiliar es obligatoria", 5);
      this.participacion.fromFile = null;
      this.participacion.fromFileFileContent = null;
      //this.OkImagenes = false;
      return;
    }
     
    this.participacion.fromFile = this.fileRecordsAux[0].name();
    if (this.fileRecordsAux[0].lastModified === 0) {
      this.participacion.fromFileFileContent = this.fileRecordsAux[0].url;
    } 
    else {
      this.readFile(this.fileRecordsAux[0].file).then((x) => {
        this.participacion.fromFileFileContent = x as string;
      });
    }
    this.participacion.fromFileFileContent =
      this.fileRecordsAux[0].urlResized;
  }
  saveAux1() {
    if(!this.validateImagenCampana.checkedImagenAuxiliar1){
      return;
    }

    if (this.fileRecordsAux1.length <= 0) {
      this.participacion.imagenAuxiliar1 = null;
      this.participacion.imagenAuxiliar1FileContent = null;
      return;
    }
     
    this.participacion.imagenAuxiliar1 = this.fileRecordsAux1[0].name();
    if (this.fileRecordsAux1[0].lastModified === 0) {
      this.participacion.imagenAuxiliar1FileContent = this.fileRecordsAux1[0].url;
    } 
    else {
      this.readFile(this.fileRecordsAux1[0].file).then((x) => {
        this.participacion.imagenAuxiliar1FileContent = x as string;
      });
    }
    this.participacion.imagenAuxiliar1FileContent =
      this.fileRecordsAux1[0].urlResized;
  }
  saveAux2() {
    if(!this.validateImagenCampana.checkedImagenAuxiliar2){
      return;
    }

    if (this.fileRecordsAux2.length <= 0) {
      this.participacion.imagenAuxiliar2 = null;
      this.participacion.imagenAuxiliar2FileContent = null;
      return;
    }
     
    this.participacion.imagenAuxiliar2 = this.fileRecordsAux2[0].name();
    if (this.fileRecordsAux2[0].lastModified === 0) {
      this.participacion.imagenAuxiliar2FileContent = this.fileRecordsAux2[0].url;
    } 
    else {
      this.readFile(this.fileRecordsAux2[0].file).then((x) => {
        this.participacion.imagenAuxiliar2FileContent = x as string;
      });
    }
    this.participacion.imagenAuxiliar2FileContent =
      this.fileRecordsAux2[0].urlResized;
  }
  saveAux3() {
    if(!this.validateImagenCampana.checkedImagenAuxiliar3){
      return;
    }

    if (this.fileRecordsAux3.length <= 0) {
      this.participacion.imagenAuxiliar3 = null;
      this.participacion.imagenAuxiliar3FileContent = null;
      return;
    }
     
    this.participacion.imagenAuxiliar3 = this.fileRecordsAux3[0].name();
    if (this.fileRecordsAux3[0].lastModified === 0) {
      this.participacion.imagenAuxiliar3FileContent = this.fileRecordsAux3[0].url;
    } 
    else {
      this.readFile(this.fileRecordsAux3[0].file).then((x) => {
        this.participacion.imagenAuxiliar3FileContent = x as string;
      });
    }
    this.participacion.imagenAuxiliar3FileContent =
      this.fileRecordsAux3[0].urlResized;
  }
  saveAux4() {
    if(!this.validateImagenCampana.checkedImagenAuxiliar4){
      return;
    }

    if (this.fileRecordsAux4.length <= 0) {
      this.participacion.imagenAuxiliar4 = null;
      this.participacion.imagenAuxiliar4FileContent = null;
      return;
    }
     
    this.participacion.imagenAuxiliar4 = this.fileRecordsAux4[0].name();
    if (this.fileRecordsAux4[0].lastModified === 0) {
      this.participacion.imagenAuxiliar4FileContent = this.fileRecordsAux4[0].url;
    } 
    else {
      this.readFile(this.fileRecordsAux4[0].file).then((x) => {
        this.participacion.imagenAuxiliar4FileContent = x as string;
      });
    }
    this.participacion.imagenAuxiliar4FileContent =
      this.fileRecordsAux4[0].urlResized;
  }


  async deleteUploadedFileTiket(fileRecord) {
    this.fileRecordsTiket = [];
    this.participacion.validacion = null;
    this.participacion.validacionFileContent = null;
    if(this.participacion.id){
      await this.$store.dispatch({
        type: "participacion/deleteTicketFile",
        data: this.participacion,
      });
    }

    this.participacion.ticket.splice(0, 1);
  }

  async deleteUploadedFileTiket2(fileRecord) {
    this.fileRecordsTiket2 = [];
    this.participacion.validacion2 = null;
    this.participacion.validacion2FileContent = null;
    if(this.participacion.id){
      await this.$store.dispatch({
        type: "participacion/deleteTicket2File",
        data: this.participacion,
      });
    }

    let indexToDelete = this.canSelectImageTicket1 && this.canSelectImageTicket2 ? 1 : 0;
    this.participacion.ticket.splice(indexToDelete, 1);
  }
  
  async deleteUploadedFileEan(fileRecord) {
    this.fileRecordsEan = [];
    this.participacion.ean = null;
    this.participacion.eanFileContent = null;
    if(this.participacion.id){
      await this.$store.dispatch({
        type: "participacion/deleteEanFile",
        data: this.participacion,
      });
    }
  }
  async deleteUploadedFileDniFrontal(fileRecord) {
    this.fileRecordsDniFrontal = [];
    this.participacion.dniFrontal = null;
    this.participacion.dniFrontalFileContent = null;
    if(this.participacion.id){
      await this.$store.dispatch({
        type: "participacion/deleteDniFrontalFile",
        data: this.participacion,
      });
    }
  }
  async deleteUploadedFileDniTrasera(fileRecord) {
    this.fileRecordsDniTrasera = [];
    this.participacion.dniTrasera = null;
    this.participacion.dniTraseraFileContent = null;
    if(this.participacion.id){
      await this.$store.dispatch({
        type: "participacion/deleteDniTraseraFile",
        data: this.participacion,
      });
    }
  }
  async deleteUploadedFileAux(fileRecord) {
    this.fileRecordsAux = [];
    this.participacion.fromFile = null;
    this.participacion.fromFileFileContent = null;
    if(this.participacion.id){
      await this.$store.dispatch({
        type: "participacion/DeleteFromFileFile",
        data: this.participacion,
      });
    }
  }
  async deleteUploadedFileAux1(fileRecord) {
    this.fileRecordsAux1 = [];
    this.participacion.imagenAuxiliar1 = null;
    this.participacion.imagenAuxiliar1FileContent = null;
    if(this.participacion.id){
      await this.$store.dispatch({
        type: "participacion/DeleteImagenAuxiliar1",
        data: this.participacion,
      });
    }
  }
  async deleteUploadedFileAux2(fileRecord) {
    this.fileRecordsAux1 = [];
    this.participacion.imagenAuxiliar2 = null;
    this.participacion.imagenAuxiliar2FileContent = null;
    if(this.participacion.id){
      await this.$store.dispatch({
        type: "participacion/DeleteImagenAuxiliar2",
        data: this.participacion,
      });
    }
  }
  async deleteUploadedFileAux3(fileRecord) {
    this.fileRecordsAux1 = [];
    this.participacion.imagenAuxiliar3 = null;
    this.participacion.imagenAuxiliar3FileContent = null;
    if(this.participacion.id){
      await this.$store.dispatch({
        type: "participacion/DeleteImagenAuxiliar3",
        data: this.participacion,
      });
    }
  }
  async deleteUploadedFileAux4(fileRecord) {
    this.fileRecordsAux1 = [];
    this.participacion.imagenAuxiliar4 = null;
    this.participacion.imagenAuxiliar4FileContent = null;
    if(this.participacion.id){
      await this.$store.dispatch({
        type: "participacion/DeleteImagenAuxiliar4",
        data: this.participacion,
      });
    }
  }

  async generarEmitirTransferencia(generar, emitir) {
    let transferenciaID;
    let wError = false;

    if (generar) {
      this.$message.loading("Generando Transferencia...", 10);
      if (this.participacion.estadoParticipacionId != 5) {
        this.$message.error(
          "No se puede generar una transferencia de una participación aún no validada.",
          5
        );
        return;
      }
      if (!this.transferencias || this.transferencias.length == 0) {
        this.$message.error(
          "Ya existe una transferencia para esta participación",
          5
        );
        return;
      }

      await this.$store
        .dispatch({
          type: "participacion/forzarTransferencia",
          data: { id: this.participacion.id },
        })
        .then((data) => {
          if (data) {
            transferenciaID = data;
            this.$message.success("Transferencia generada!");
            if (!emitir) {
              location.reload();
            }
          } else {
            this.$message.error("Error al generar transferencia", 5);
            wError = true;
          }
        })
        .catch((e) => {
          this.$message.error("Error al generar transferencia", 5);
          wError = true;
        });
    }

    if (emitir && !wError) {
      this.$message.loading("Generando fichero SEPA...");
      if (
        !transferenciaID &&
        this.transferencias &&
        this.transferencias.length > 0
      ) {
        transferenciaID =
          this.transferencias[this.transferencias.length - 1].id;
      }
      alert(transferenciaID);
      if (transferenciaID) {
        await this.$store
          .dispatch({
            type: "transferencia/urgent",
            data: { id: transferenciaID },
          })
          .then((data) => {
            this.$message.success("Transferencia emitida!");
            location.reload();
          });
      } else {
        this.$message.error("Error al generar SEPA", 5);
      }
    }
  }

  formatEstadoEnvio(estadoEnvioId){
    var estadoText = '';
    switch(estadoEnvioId){
      case 1:
        estadoText = 'Pendiente';
        break;
      case 2:
        estadoText = 'Enviado';
        break;
      case 3:
        estadoText = 'Devuelto';
        break;
      case 4:
        estadoText = 'Cancelado';
        break;
      case 5:
        estadoText = 'Pendiente Stock';
        break;
      case 6:
        estadoText = 'En Tramite';
        break;
      default:
        estadoText = 'Pendiente';
        break;
    }

    return estadoText;
  }

  participacionRule = {
    nombre: {
      required: { required: true, min: 2, max: 32 },
      message: this.L("ThisFieldIsRequired", undefined, this.L("Nombre")),
      placeholder: this.L("Nombre"),
      trigger: "blur",
    },
    apellido1: {
      required: { required: true, min: 2, max: 32 },
      message: this.L("ThisFieldIsRequired", undefined, this.L("Apellido1")),
      placeholder: this.L("Apellido1"),
      trigger: "blur",
    },
    apellido2: {
      required: { min: 2, max: 32 },
      message: this.L("ThisFieldIsRequired", undefined, this.L("Apellido2")),
      placeholder: this.L("Apellido2"),
      trigger: "blur",
    },
    email: {
      required: { required: true, email: true },
      message: this.L("ThisFieldIsRequired", undefined, this.L("Email")),
      placeholder: this.L("Email"),
      trigger: "blur",
    },
    telefono: {
      required: { required: true, min: 2, max: 32 },
      message: this.L("ThisFieldIsRequired", undefined, this.L("Telefono")),
      placeholder: this.L("Telefono"),
      trigger: "blur",
    },
    fechaNacimiento: {
      required: { required: false },
      message: this.L(
        "ThisFieldIsRequired",
        undefined,
        this.L("FechaNacimiento")
      ),
      placeholder: this.L("FechaNacimiento"),
      trigger: "blur",
      type: "date",
    },
    dni: {
      required: { required: false, regex: "^[xXyYzZ]\\d{7}[aA-zZ]$|^(\\d{8})([aA-zZ])$", min: 2, max: 32 },
      message: this.L("ThisFieldIsRequired", undefined, this.L("DNI")),
      placeholder: this.L("DNI/NIE"),
      trigger: "blur",
    },
    campana: {
      required: { filtrableDropdownRequired: true },
      message: this.L("ThisFieldIsRequired", undefined, this.L("Campana")),
      placeholder: this.L("Campaña"),
      trigger: "blur",
    },
    ekon: {
      placeholder: this.L("Codigo Ekon")
    },
    sexo: {
      required: { filtrableDropdownRequired: false },
      placeholder: this.L("Sexo"),
      trigger: "blur",
    },
    estadoParticipacion: {
      required: { filtrableDropdownRequired: true },
      message: this.L(
        "ThisFieldIsRequired",
        undefined,
        this.L("EstadoParticipacion")
      ),
      placeholder: this.L("EstadoParticipacion"),
      trigger: "blur",
    },
    subEstadoParticipacion: {
      required: { filtrableDropdownRequired: false },
      message: this.L(
        "ThisFieldIsRequired",
        undefined,
        this.L("SubEstadoParticipacion")
      ),
      placeholder: this.L("SubEstadoParticipacion"),
      trigger: "blur",
    },
  };
  columns = [
    {
      title: this.L("Action"),
      dataIndex: "changeTypeName",
    },
    {
      title: this.L("User"),
      dataIndex: "userName",
    },
    {
      title: this.L("Fecha"),
      dataIndex: "changeTime",
      scopedSlots: { customRender: "date" },
    },
    {
      scopedSlots: { customRender: "detailBtn" },
      width: 10,
      title: "",
    },
  ];
}
