import Entity from "../entity";

export default class ItemTiket extends Entity<number> {  
    name: string;
    uuid: string;
    name_score: number;
    price: string;
    price_score: number;
    barcode: string;
    units: number;
    units_score: number;
    unit_price:number;
    data:string;
}