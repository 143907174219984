
import EstadoEnvio from "../../../store/entities/OZONE/estadoEnvioOzone";
import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";
import AbpBase from "../../../lib/abpbase";
import Envio from "../../../store/entities/OZONE/envio";

@Component({})
export default class EditEnvio extends AbpBase {
    @Prop({ type: Boolean, default: false }) value: boolean;
    @Prop( {type: Object, default: null }) envio: Envio;
    @Prop({ type: Boolean, default: false }) isReenvio: boolean;

    newEnvio: Envio = new Envio();
    form: any;
    destinatario: string = '';
    newEstado: number = 1;

    get confirmLoading() {
        return this.$store.state.envio.loading || this.$store.state.estadoEnvio.loading;
    }

    get title(){
        return this.isReenvio ? this.L("Reenviar") : this.L("Editar");
    }

    get estadosEnvio(){
        return this.$store.state.estadoEnvio.list;
    }

    get motivosDevolucion(){
        return this.$store.state.motivoDevolucion.list;
    }

    formRules = {
        articulo: {
            labelWrapper: { span: 18, offset: 0 },
            inputWrapper: { span: 18, offset: 0 }
        },
        articuloUnidades: {
            labelWrapper: { span: 4, offset: 0 },
            inputWrapper: { span: 4, offset: 0 }
        },
        telefono: {
            validation: ""
        },
        email: {
            validation: ""
        }
    }

    async mounted(){
        await this.$store.dispatch({
            type: "estadoEnvio/getAll"
        });
        
        await this.$store.dispatch({
            type: "motivoDevolucion/getAll",
        });

        this.form = this.$form.createForm(this, {
            name: "edit-envio-form"
        });
    }

    @Watch("value")
    syncTransferencia() {
        if (this.value) {
            this.newEnvio = this.envio ? this.envio : null;
            this.destinatario = `${this.newEnvio.nombre} ${this.newEnvio.apellido1} ${this.newEnvio.apellido2}`
        } 
        else {
            this.newEnvio = null;
        }
    }

    @Watch("newEstado")
    changeEstadoEnvio(){
        if(this.newEstado != 3){
            this.newEnvio.motivoDevolucionId = null;
            this.newEnvio.motivoDevolucion = null;
        }
    }

    async handleOk(){
        if(this.isReenvio){
            await this.$store.dispatch({
                type: "envio/reenviar",
                data: this.newEnvio
            }).then((value) => {
                this.$emit("save-success", value);
            });
        }
        else{
            this.newEnvio.estadoEnvioId = this.newEstado;
            await this.$store.dispatch({
                type: "envio/updateEnvio",
                data: this.newEnvio
            }).then((value) => {
                this.$emit("save-success", value);
            });
        }
        
        //this.$emit("save-success");
        this.$emit("input", false);
    }

    handleCancel(){
        this.$emit("input", false);
    }
}
