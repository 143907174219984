
import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator'
import Util from '../../../lib/util'
import AbpBase from '../../../lib/abpbase'
import Modal from '../../../components/Modal.vue'
import BaseButton from '@/components/BaseButton.vue'
import BaseCheckbox from '@/components/Inputs/BaseCheckbox.vue'
import Ticket from '@/store/entities/OZONE/ticket'
import moment from 'moment'
import Participacion from '@/store/entities/OZONE/participacion'
import ParticipacionDto from '@/store/entities/OZONE/participacionDto'
import ItemTiket from '@/store/entities/OZONE/itemTiket'
import EanTicket from '@/store/entities/OZONE/eanTicket'

class ParticipacionEans extends Participacion {
  imagenEan: string;
}
@Component({
  components: { Modal, BaseButton, BaseCheckbox }
})

export default class ProcesaImagenParticipacion extends AbpBase {
  name: 'edit-estados-participacion'
  @Prop({ type: Boolean, default: true }) value: boolean
  @Prop({ type: String }) id: string
  @Prop({ type: String, default: '' }) fileNameEan: string
  @Prop() fileContentEan: any
  participacion: ParticipacionEans = new ParticipacionEans();
  title = ''
  done = false
  documentId = 0
  estado = ''
  ticketEan: EanTicket
  interval: number
  fases: Array<string> = []
  inicio: string = ''
  async created() {}
  async save() {
      
    this.$emit('input', false)
    
    this.$emit('save-success', this.ticketEan)
    this.resetFields()
  }
  close() {
    this.$emit('input', false)
    this.resetFields()
  }
  getDate() {
    return '  ' + moment(new Date()).format('DD/MM/YYYY HH:mm:ss')
  }
  async shown() {
    
    this.inicio = '  ' + moment(new Date()).format('DD/MM/YYYY HH:mm:ss')
    this.title = 'Procesando Imagen'
    this.estado = 'Cargando Imagen Ean'
    
    this.fases.push(this.estado + '  ' + moment(new Date()).format('DD/MM/YYYY HH:mm:ss'))
    await this.postfilEanManual().then(async (x: any) => {
      
      if (x.status == 200) {
        
        if(x.data.eans.length !=0){
          var resultTicketEan = new EanTicket();
          var barcodes = x.data.eans.map(function(e){return e.data;}).join(",");
          resultTicketEan.barcode= barcodes;
          this.ticketEan = resultTicketEan;
        }
        else{
          var resultTicketEan = new EanTicket();
          resultTicketEan.barcode=null;
          this.ticketEan = resultTicketEan;
        }
      }
      else{
        this.ticketEan = null
      }
      this.save()
      this.$bvModal.hide(this.id)
    })
  }
  resetFields() {
    clearInterval(this.interval)
    this.ticketEan = null
    this.done = false
    this.estado = ''
    this.documentId = 0
    this.fases = []
  }

  postfileEan() {
    return new Promise(async (resolve) => {
      
      var form = new FormData()
      
      this.participacion.id= this.$store.state.participacion.editParticipacion.id
      this.participacion.campanaId= this.$store.state.participacion.editParticipacion.campanaId
      this.participacion.ean=this.$store.state.participacion.editParticipacion.ean
      this.participacion.eanFileContent = this.$store.state.participacion.editParticipacion.eanFileContent
      
      await this.$store
        .dispatch({
          type: 'participacion/postfilEan',
          data: this.participacion
        })
        .then((x) => {
          resolve(x)
        })
    })
  }
  postfilEanManual(){
    
    // this.participacion.id= this.$store.state.participacion.editParticipacion.id
    // this.participacion.campanaId= this.$store.state.participacion.editParticipacion.campanaId
    // this.participacion.ean=this.$store.state.participacion.editParticipacion.ean
    // this.participacion.eanFileContent = this.$store.state.participacion.editParticipacion.eanFileContent
    
    return new Promise(async (resolve) => {
      var form = new FormData()
      form.append('file', this.fileContentEan as Blob,this.fileNameEan)
      await this.$store
        .dispatch({
          type: 'participacion/postfilEanManual',
          data: form
        })
        .then((x) => {
          resolve(x)
        })
    })
  }
}

