
import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";
import Util from "../../../lib/util";
import AbpBase from "../../../lib/abpbase";
import Modal from "../../../components/Modal.vue";
import BaseButton from "../../../components/BaseButton.vue";
import BaseCheckbox from "../../../components/Inputs/BaseCheckbox.vue";
import Ticket from "../../../store/entities/OZONE/ticket";
import moment from "moment";

@Component({
  components: { Modal, BaseButton, BaseCheckbox },
})
export default class ProcesaImagenParticipacion extends AbpBase {
  name: "edit-estados-participacion";
  @Prop({ type: Boolean, default: true }) value!: boolean;
  @Prop({ type: String }) id!: string;
  @Prop({ type: String, default: "" }) fileName!: string;
  @Prop() fileContent!: any;

  title = "";
  done = false;
  documentId = 0;
  estado = "";
  ticketInfo: Ticket | null = null;
  interval: ReturnType<typeof setInterval> | null = null;
  fases: Array<string> = [];
  inicio: string = "";
  uploadId: number = 0;

  async created() {}

  async save() {
    this.$emit("input", false);
    this.$emit("save-success", this.ticketInfo);
    this.resetFields();
  }

  close() {
    this.$emit("input", false);
    this.resetFields();
  }

  getDate() {
    return "  " + moment(new Date()).format("DD/MM/YYYY HH:mm:ss");
  }

  async shown() {
    this.inicio = "  " + moment(new Date()).format("DD/MM/YYYY HH:mm:ss");
    this.title = "Procesando Imagen";
    this.estado = "Subiendo imagen";
    this.fases.push(
      this.estado + "  " + moment(new Date()).format("DD/MM/YYYY HH:mm:ss")
    );
    await this.postfile().then(async (x: any) => {
      if (x.status === 200) {
        this.uploadId = x.data.uploadId;
        this.waitUntil(() => this.done === true).then(() => {});
      }
    });
  }

  resetFields() {
    if (this.interval !== null) {
      clearInterval(this.interval);
    }
    this.ticketInfo = null;
    this.done = false;
    this.estado = "";
    this.documentId = 0;
    this.fases = [];
  }

  waitUntil(condition) {
    return new Promise((resolve) => {
      this.interval = setInterval(async () => {
        if (!condition()) {
          await this.postdata().then((x: any) => {
            if (x.status !== "COMPLETED") {
              // Continuar lógica si no está completado
            } else {
              this.done = true;
              this.ticketInfo = x.data;
              this.fases.push(
                "Hecho" +
                  "  " +
                  moment(new Date()).format("DD/MM/YYYY HH:mm:ss")
              );
            }
          });

          return;
        }
        if (this.interval !== null) {
          clearInterval(this.interval);
        }
        this.save();
        this.$bvModal.hide(this.id);
        resolve(true);
      }, 2000);
    });
  }

  postfile() {
    return new Promise(async (resolve) => {
      var form = new FormData();
      form.append("file", this.fileContent);
      await this.$store
        .dispatch({
          type: "participacion/postfile",
          data: form,
        })
        .then((x) => {
          resolve(x);
        });
    });
  }

  postdata() {
    return new Promise(async (resolve) => {
      await this.$store
        .dispatch({
          type: "participacion/postdata",
          data: this.uploadId,
        })
        .then((x) => {
          resolve(x);
        });
    });
  }

  getStatus() {
    return new Promise(async (resolve) => {
      await this.$store
        .dispatch({
          type: "participacion/getStatus",
          data: this.documentId,
        })
        .then((x) => {
          resolve(x);
        });
    });
  }

  getInfo() {
    return new Promise(async (resolve) => {
      await this.$store
        .dispatch({
          type: "participacion/getInfo",
          data: this.documentId,
        })
        .then((x) => {
          resolve(x);
        });
    });
  }
}
